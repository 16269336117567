import { localizer } from '@skype/bew-localization';
import React, { SyntheticEvent } from 'react';
import { logger } from '../helpers/LoggerHelper';
import { buildRedirectQueryParameters } from '../meetnow/MeetNowEdgeHandler';
import './JoinWidget.less';

export interface JoinWidgetProps {
    basePath: string;
    isJoinLinkValid: boolean;
    isRequestInProgress: boolean;
    allowedQueryParams: string[];
    onChangeHandler: (event: React.FormEvent<HTMLInputElement>) => void;
    onSubmitHandler: (joinButtonRef: React.RefObject<HTMLButtonElement>) => (event: SyntheticEvent) => void;
}

export class JoinWidget extends React.Component<JoinWidgetProps> {
    private textBox = React.createRef<HTMLInputElement>();
    private joinButton = React.createRef<HTMLButtonElement>();

    componentDidMount() {
        if (this.textBox && this.textBox.current) {
            this.textBox.current.focus();
        }
    }

    render() {
        return (<div className='joinWidgetWrapper'>
            <div className='titleWrapper'>
                <img src={ `${ this.props.basePath }/images/icons/generatelink@2x.png` } alt='' aria-hidden='true' />
                <h1 className='widgetTitle'>{ localizer.getString('Meetings.hdr_join_page_title') }</h1>
            </div>
            <div className={ `inputWrapper ${!this.props.isJoinLinkValid ? 'error' : ''}` }>
                <label htmlFor='joinLinkInput'>{ localizer.getString('Meetings.txt_meeting_link_or_code') }</label>
                <input
                    ref={ this.textBox }
                    className={ !this.props.isJoinLinkValid ? 'error' : '' }
                    type='text'
                    id='joinLinkInput'
                    placeholder='https://join.skype.com/code'
                    onKeyPress={ this.handleKeyPress }
                    onChange={ this.props.onChangeHandler } />
                <div className='joinLinkErrorText'>{ !this.props.isJoinLinkValid && localizer.getString('Meetings.txt_invalid_link_or_code') }</div>
            </div>
            <div className='buttonWrapper'>
                <button
                    ref={  this.joinButton }
                    className='buttonBlue buttonJoin'
                    disabled={ this.props.isRequestInProgress }
                    onClick={ this.props.onSubmitHandler(this.joinButton) }>
                    { localizer.getString('Common.btn_join') }
                </button>
            </div>
            <div className='createNewMeeting'>
                { localizer.get('Meetings.txt_dont_have_meeting_link', {
                    linkCreate: {
                        type: 'a',
                        href: this.createMeetingUrl,
                        className: 'createMeetingLink',
                        onClick: (event: MouseEvent) => {
                            // The library always generates target="_blank" attribute so this is an override
                            event.preventDefault();
                            logger.action({ name: 'createMeetingLinkClicked' });

                            window.location.replace(this.createMeetingUrl);
                        },
                    },
                }) }
            </div>
            <span className='termsAndConditions'>
                { localizer.get('txt_launch_guest_accept_tos', {
                    linkPrivacy: { type: 'a', href: 'https://go.microsoft.com/fwlink/?LinkId=521839' },
                    linkTerms: { type: 'a', href: 'https://go.microsoft.com/fwlink/?LinkID=246338' },
                }) }
            </span>
        </div>);
    }

    private handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.joinButton.current?.click();
        }
    }

    private get createMeetingUrl() {
        return `/meetnow?${buildRedirectQueryParameters(this.props.allowedQueryParams, logger.getCorrelationId())}`;
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import '../../../vendor/silentLogin';
import { loadTranslation } from '../helpers/LocalizerHelper';
import { logger } from '../helpers/LoggerHelper';
import getMeetNowSettings from '../meetnow/MeetNowSettings';
import './index.less';
import { MeetNowJoin } from './MeetNowJoin';

const settings = getMeetNowSettings();
logger.init(settings.MeetNowEdgeAriaTenantId, 'meetnow_join_');
logger.sessionStarted({ useUnifiedView: false, wasPreLoggedin: false });

const container = document.getElementById('meetnowContainer')!;
const language = document.getElementsByTagName('html')[0].lang;
const basePath = container.dataset.basePath!;

loadTranslation(language);

ReactDOM.render(<MeetNowJoin language={ language } basePath={ basePath } settings={settings} />, container);

import React, { SyntheticEvent } from 'react';
import { ErrorModal } from '../components/common/ErrorModal';
import { canJoinOnWeb } from '../helpers/EnvironmentHelper';
import { CommonProperty, logger } from '../helpers/LoggerHelper';
import { silentLoginAsync, SilentLoginResult } from '../helpers/SilentLoginHelper';
import { IMeetNowSettings } from '../meetnow/MeetNowSettings';
import { handleJoin, validateJoinLinkOrCode } from './JoinHandler';
import { JoinWidget } from './JoinWidget';
import './MeetNowJoin.less';

export interface MeetNowJoinProps {
    readonly language: string;
    readonly basePath: string;
    readonly settings: IMeetNowSettings;
}

export interface MeetNowJoinState {
    isLoginInProgress: boolean;
    joinButtonClicked: boolean;
    handleJoinError?: Error;
    joinLink: string;
    isJoinLinkValid: boolean;
    skypeToken?: string;
}

export class MeetNowJoin extends React.Component<MeetNowJoinProps, MeetNowJoinState> {
    constructor(props: MeetNowJoinProps) {
        super(props);
        this.state = {
            isLoginInProgress: true,
            joinButtonClicked: false,
            handleJoinError: undefined,
            joinLink: '',
            isJoinLinkValid: true,
            skypeToken: undefined,
        };
    }

    componentDidMount() {
        this.setState({ isLoginInProgress: true });
        silentLoginAsync()
            .then((loginResult: SilentLoginResult | undefined) => {
                const newState: MeetNowJoinState = { ...this.state, isLoginInProgress: false };
                if (!loginResult || !loginResult.skypetoken) {
                    logger.initCommonProperty({ key: CommonProperty.IsLoggedIn, value: false });
                    logger.initCommonProperty({
                        key: CommonProperty.IsUnsupportedPlatformOrBrowser,
                        value: canJoinOnWeb,
                    });
                    logger.sessionIntermediateStep({ eventType: 'silentLoginFinished' });
                } else {
                    logger.initCommonProperty({ key: CommonProperty.IsLoggedIn, value: true });
                    logger.initCommonProperty({
                        key: CommonProperty.IsUnsupportedPlatformOrBrowser,
                        value: canJoinOnWeb,
                    });
                    logger.sessionIntermediateStep({ eventType: 'silentLoginFinished' });
                    logger.addSkypeIdAndMsaCidHexCommonProperties(loginResult.skypetoken, false);
                    newState.skypeToken = loginResult.skypetoken;
                }
                this.setState(newState);
            })
            .catch((error) => {
                if (error.error === 'invalid_grant') { // User is not authenticated with MSA
                    logger.initCommonProperty({ key: CommonProperty.IsLoggedIn, value: false });
                    logger.initCommonProperty({
                        key: CommonProperty.IsUnsupportedPlatformOrBrowser,
                        value: canJoinOnWeb,
                    });
                    logger.sessionIntermediateStep({ eventType: 'silentLoginFinished' });
                }
                this.setState({ isLoginInProgress: false });
            });
    }

    render() {
        return (<div className='appWrapper'>
            <div className='joinWidgetFlex'>
                <JoinWidget
                    basePath={ this.props.basePath }
                    isJoinLinkValid={ this.state.isJoinLinkValid }
                    isRequestInProgress={ this.state.joinButtonClicked }
                    allowedQueryParams={ this.props.settings.MeetNowEdgeAllowedQueryParameters }
                    onChangeHandler={ this.joinLinkChangeHandler.bind(this) }
                    onSubmitHandler={ this.joinMeetingHandler.bind(this) } />
            </div>
            {
                this.state.handleJoinError &&
                <ErrorModal
                    error={ this.state.handleJoinError }
                    assetsBasePath={ this.props.basePath }
                    onClose={
                        () => {
                            this.setState({
                                joinButtonClicked: false,
                                isJoinLinkValid: false,
                                handleJoinError: undefined,
                            });
                        }
                    } />
            }
        </div>);
    }

    private joinLinkChangeHandler(event: React.FormEvent<HTMLInputElement>) {
        this.setState({ joinLink: event.currentTarget.value, isJoinLinkValid: true });
    }

    private joinMeetingHandler(joinButtonRef: React.RefObject<HTMLButtonElement>) {
        return (event: SyntheticEvent) => {
            event.preventDefault();

            if (this.state.joinButtonClicked) {
                return;
            }

            logger.action({ name: 'joinButtonClicked' });
            this.setState({ joinButtonClicked: true });
            validateJoinLinkOrCode(this.props.settings, this.state.joinLink)
                .then((result) => {
                    if (!result) {
                        this.setState({ joinButtonClicked: false, isJoinLinkValid: false }, this.handleButtonFocusAfterValidation(joinButtonRef));
                    } else {
                        this.setState({ isJoinLinkValid: true });
                        handleJoin(this.props.settings, result, this.state.skypeToken).catch((err) => {
                            logger.error(err);
                            this.setState({
                                joinButtonClicked: false,
                                handleJoinError: err,
                            }, this.handleButtonFocusAfterValidation(joinButtonRef));
                        });
                    }
                })
                .catch(() => this.setState({ joinButtonClicked: false, isJoinLinkValid: false }, this.handleButtonFocusAfterValidation(joinButtonRef)));
        };
    }

    private handleButtonFocusAfterValidation(joinButtonRef: React.RefObject<HTMLButtonElement>): () => void {
        return () => {
            if (joinButtonRef && joinButtonRef.current) {
                joinButtonRef.current.focus();
            }
        };
    }
}
